.slider {
    height: 10px;
    width: 100%;
    background-color: #ddd;
    border-radius: 5px;
    margin-top: 10px;
}

/* Styles for the slider thumb */
.slider .thumb {
    width: 20px;
    height: 20px;
    background: #2f2b2b;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    top:-5px;
}

/* Styles for the slider active state */
.slider .thumb.active {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}